@import '../../styles/Global.scss';

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 64px;
    z-index: 1000;

    &-right {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        gap: 1rem;

        &-account {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;
            gap: 1rem;
            padding: 10px;
            // width: 400px;
            // background-color: darken(#282c34, 3%);
            border-radius: 10px;

            &-info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                gap: 0.5rem;
                font-size: 14px;
                font-weight: 500;
                color: #ffffff;

                &-name {
                    font-size: 16px;
                    font-weight: 600;
                }

                @media screen and (max-width: 900px){
                    font-size: 1.75rem;

                }
            }
        }

        .hamburger {
            display: none;
            cursor: pointer;
        }

        a, p {
            // margin-left: 20px;
            text-decoration: none;
            color: #ffffff;
            font-size: 16px;
            font-weight: 500;
            transition: all 0.2s ease-in-out;

            &:hover {
                color: #61f6fb;
            }
        }

        .navigationButtonContainer {
            border: 2px solid rgba(220, 232, 255, 0.75);
            background-color: rgba(255, 255, 255, 0.1);
            width: 100px;
            transition: all 0.2s ease-in-out;
            color: white;

            &:hover {
                // border: none;          makes it look bouncy, doesnt look bad but doesnt fit the rest of the buttons
                background-color:rgba(220, 232, 255, 1);
            }
        }

        .ctaButton {
            width: 150px;
            padding: 2px;
        }

        .ctaButton, .navigationButtonContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            border-radius: 5px;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            overflow: hidden;

            .inside{
                display: flex;
                min-width: 300px;
                height: 300px;
                background: radial-gradient(ellipse at bottom, rgb(6, 72, 196), rgb(87, 235, 255));
                animation: 6s infinite ctainside;
                transition: all 0.2s ease-in-out;
            }

            a {
                position: absolute;
            }

            &:hover {
                
                .inside {
                    animation-play-state: running;
                }

                .navigationButton {
                    // color: #00e1ff;
                    color:rgba(2, 7, 22, 0.8);
                }

                .ctaText {
                    color: #00e1ff;
                }
            }
        }
    }

    &-left {
        .navbar-logo-mobile {
            display: none;
        }
        @media screen and (max-width: 900px){
            .navbar-logo{
                display: none;
            }

            .navbar-logo-mobile {
                display: flex;
                width: 48px;
                height: 48px;
            }
        }
    }

    @media screen and (max-width: 900px){

        .nav-pc, .navbar-right-account {
            display: none;
        }

        .hamburger {
            display: flex;
            margin-left: 10px;

            img {
                width: 32px;
            }
        }

        // a {
        //     font-size: 0.5rem;
        // }

        // .navbar-right {
        //     display: none;
        // }
    }
}

.hamburger-menu {
    // display: flex;
    position: absolute;
    flex-direction: column;
    // background: rgba(34, 34, 34, 0.9);
    padding-top: 100px;
    z-index: 999;
    min-height: calc(100vh - 110px);
    width: 100vw;
    color: white;
    // opacity: 0;

    .background {
        position: absolute;
        min-height: calc(100vh - 110px);
        width: 100vw;
        background: rgba(34, 34, 34, 0.8);
        filter: blur(10px);
        z-index: 995;
    }

    .menuContent {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: calc(100vh - 110px);
        z-index: 999;
    }

    .navbar-right-account {
        width: 100%;
        flex-direction: column;
        border-top: 1px solid rgba(255, 255, 255, 0.75);
        border-bottom: 1px solid white;
        border-radius: 0px;
    }

    .navigationButtonContainer {
        width: 100%;
    }

    .menuSegment {
        padding: 30px 0px;
        background-color: rgba(0,0,0,0);
        border-top:1px solid rgba(255, 255, 255, 0.3);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        a {
            color: white;
            text-decoration: none;
        }

        &:hover {
            background-color: rgba(0,0,0,0.2);
        }
    }

    .lastSegment {
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }

    #disconnectContainer{
        display: flex;
        justify-content: center;
    }

    #disconnectButton {
        margin: 20px 0px;
        font-weight: 500;
        width: 70%;
        // border-top: 1px solid white;
        // border-bottom: 1px solid white;
        background: radial-gradient(rgba(0, 190, 190, 0.8), transparent 70%);
        padding: 10px 0px;
    }
}

@keyframes ctainside {
    0% {
        transform: rotate(0);
    }
    100%{
        transform: rotate(360deg);
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
        display: flex;
    }
    99%{
        opacity: 1;
    }
}

@keyframes fadeout {
    0% {
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}